import React from 'react';

function BoxScore() {
  return (
    <div className="box-score-container">
      <h1>Box Scores page coming soon!</h1>
    </div>
  );
}

export default BoxScore;
